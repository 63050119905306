import React from 'react';
import Layout from '../components/layout';

const PrivacyPage = () => (
  <Layout>
    <div className="coc container">
      <h1>Datenschutzerkärung</h1>
      <p>
        Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck
        der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“)
        im Rahmen der Erbringung unserer Leistungen sowie innerhalb unseres
        Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und
        Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media
        Profile auf (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im
        Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“
        oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der
        Datenschutzgrundverordnung (DSGVO).{' '}
      </p>
      <h2>Verantwortlicher</h2>
      <p>
        Timo Stollenwerk
        <br />
        kitconcept GmbH
        <br />
        Vorgebirgsstraße 80
        <br />
        53119 Bonn
        <br />
        <a href="https://kitconcept.com/"> www.kitconcept.com</a>
        <br />
        <a href=" mailto:info@kitconcept.com?"> info@kitconcept.com</a>
      </p>
      <p>
        Sitz der Gesellschaft: Bonn <br />
        Registergericht der Gesellschaft: Amtsgericht Bonn
        <br />
        Handelsregister Nummer: HRB 22064
        <br />
        USt-IdNr.: DE305158391
        <br />
        Geschäftsführer: Timo Stollenwerk
      </p>
      <h2>Arten der verarbeiteten Daten</h2>
      <ul>
        <li>Bestandsdaten (z.B., Personen-Stammdaten, Namen oder Adressen).</li>
        <li>Kontaktdaten (z.B., E-Mail, Telefonnummern).</li>
        <li>Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).</li>
        <li>
          Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten,
          Zugriffszeiten).
        </li>
        <li>
          Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).
        </li>
      </ul>
      <h2>Kategorien betroffener Personen</h2>
      <p>
        Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die
        betroffenen Personen zusammenfassend auch als „Nutzer“).
      </p>
      <h2>Zweck der Verarbeitung</h2>
      <ul>
        <li>
          Zurverfügungstellung des Onlineangebotes, seiner Funktionen und
          Inhalte.
        </li>
        <li>Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.</li>
        <li>Sicherheitsmaßnahmen.</li>
        <li>Reichweitenmessung/Marketing</li>
      </ul>
      <h2>Verwendete Begrifflichkeiten </h2>
      <p>
        „Personenbezogene Daten“ sind alle Informationen, die sich auf eine
        identifizierte oder identifizierbare natürliche Person (im Folgenden
        „betroffene Person“) beziehen; als identifizierbar wird eine natürliche
        Person angesehen, die direkt oder indirekt, insbesondere mittels
        Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
        Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder
        mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck
        der physischen, physiologischen, genetischen, psychischen,
        wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen
        Person sind.
      </p>
      <p>
        „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren
        ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
        personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch
        jeden Umgang mit Daten.
      </p>
      <p>
        „Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer
        Weise, dass die personenbezogenen Daten ohne Hinzuziehung zusätzlicher
        Informationen nicht mehr einer spezifischen betroffenen Person
        zugeordnet werden können, sofern diese zusätzlichen Informationen
        gesondert aufbewahrt werden und technischen und organisatorischen
        Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen
        Daten nicht einer identifizierten oder identifizierbaren natürlichen
        Person zugewiesen werden.
      </p>
      <p>
        „Profiling“ jede Art der automatisierten Verarbeitung personenbezogener
        Daten, die darin besteht, dass diese personenbezogenen Daten verwendet
        werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche
        Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich
        Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche
        Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder
        Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.
      </p>
      <p>
        Als „Verantwortlicher“ wird die natürliche oder juristische Person,
        Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit
        anderen über die Zwecke und Mittel der Verarbeitung von
        personenbezogenen Daten entscheidet, bezeichnet.
      </p>
      <p>
        „Auftragsverarbeiter“ eine natürliche oder juristische Person, Behörde,
        Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag
        des Verantwortlichen verarbeitet.
      </p>
      <h2>Maßgebliche Rechtsgrundlagen</h2>
      <p>
        Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen
        unserer Datenverarbeitungen mit. Für Nutzer aus dem Geltungsbereich der
        Datenschutzgrundverordnung (DSGVO), d.h. der EU und des EWG gilt, sofern
        die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird,
        Folgendes: Die Rechtsgrundlage für die Einholung von Einwilligungen ist
        Art. 6 Abs. 1 lit. a und Art. 7 DSGVO;
      </p>
      <p>
        Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
        Leistungen und Durchführung vertraglicher Maßnahmen sowie Beantwortung
        von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO;
      </p>
      <p>
        Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
        rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO;
      </p>
      <p>
        Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder
        einer anderen natürlichen Person eine Verarbeitung personenbezogener
        Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
        Rechtsgrundlage.
      </p>
      <p>
        Die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer
        berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO.
      </p>
      <p>
        Die Verarbeitung von Daten zu anderen Zwecken als denen, zu denen sie
        ehoben wurden, bestimmt sich nach den Vorgaben des Art 6 Abs. 4 DSGVO.
      </p>
      <p>
        Die Verarbeitung von besonderen Kategorien von Daten (entsprechend Art.
        9 Abs. 1 DSGVO) bestimmt sich nach den Vorgaben des Art. 9 Abs. 2 DSGVO.
      </p>
      <h2>Sicherheitsmaßnahmen</h2>
      <p>
        Wir treffen nach Maßgabe der gesetzlichen Vorgabenunter Berücksichtigung
        des Stands der Technik, der Implementierungskosten und der Art, des
        Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
        unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos
        für die Rechte und Freiheiten natürlicher Personen, geeignete technische
        und organisatorische Maßnahmen, um ein dem Risiko angemessenes
        Schutzniveau zu gewährleisten.
      </p>
      <p>
        Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
        Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen
        Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der
        Eingabe, Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung.
        Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von
        Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung der
        Daten gewährleisten. Ferner berücksichtigen wir den Schutz
        personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von
        Hardware, Software sowie Verfahren, entsprechend dem Prinzip des
        Datenschutzes durch Technikgestaltung und durch datenschutzfreundliche
        Voreinstellungen.
      </p>
      <h2>
        Zusammenarbeit mit Auftragsverarbeitern, gemeinsam Verantwortlichen und
        Dritten
      </h2>
      <p>
        Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen
        Personen und Unternehmen (Auftragsverarbeitern, gemeinsam
        Verantwortlichen oder Dritten) offenbaren, sie an diese übermitteln oder
        ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur auf
        Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der
        Daten an Dritte, wie an Zahlungsdienstleister, zur Vertragserfüllung
        erforderlich ist), Nutzer eingewilligt haben, eine rechtliche
        Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten
        Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc.).
      </p>
      <p>
        Sofern wir Daten anderen Unternehmen unserer Unternehmensgruppe
        offenbaren, übermitteln oder ihnen sonst den Zugriff gewähren, erfolgt
        dies insbesondere zu administrativen Zwecken als berechtigtes Interesse
        und darüberhinausgehend auf einer den gesetzlichen Vorgaben
        entsprechenden Grundlage.
      </p>
      <h2>Übermittlungen in Drittländer</h2>
      <p>
        Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen
        Union (EU), des Europäischen Wirtschaftsraums (EWR) oder der Schweizer
        Eidgenossenschaft) verarbeiten oder dies im Rahmen der Inanspruchnahme
        von Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an
        andere Personen oder Unternehmen geschieht, erfolgt dies nur, wenn es
        zur Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer
        Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf
        Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich
        gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten oder lassen wir
        die Daten in einem Drittland nur beim Vorliegen der gesetzlichen
        Voraussetzungen. D.h. die Verarbeitung erfolgt z.B. auf Grundlage
        besonderer Garantien, wie der offiziell anerkannten Feststellung eines
        der EU entsprechenden Datenschutzniveaus (z.B. für die USA durch das
        „Privacy Shield“) oder Beachtung offiziell anerkannter spezieller
        vertraglicher Verpflichtungen.
      </p>
      <h2>Rechte der betroffenen Personen</h2>
      <p>
        Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
        betreffende Daten verarbeitet werden und auf Auskunft über diese Daten
        sowie auf weitere Informationen und Kopie der Daten entsprechend den
        gesetzlichen Vorgaben.
      </p>
      <p>
        Sie haben entsprechend. den gesetzlichen Vorgaben das Recht, die
        Vervollständigung der Sie betreffenden Daten oder die Berichtigung der
        Sie betreffenden unrichtigen Daten zu verlangen.
      </p>
      <p>
        Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht zu verlangen,
        dass betreffende Daten unverzüglich gelöscht werden, bzw. alternativ
        nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der
        Verarbeitung der Daten zu verlangen.
      </p>
      <p>
        Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die
        Sie uns bereitgestellt haben nach Maßgabe der gesetzlichen Vorgaben zu
        erhalten und deren Übermittlung an andere Verantwortliche zu fordern.
      </p>
      <p>
        Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das Recht, eine
        Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
      </p>
      <h2>Widerrufsrecht</h2>
      <p>
        Sie haben das Recht, erteilte Einwilligungen mit Wirkung für die Zukunft
        zu widerrufen.
      </p>
      <h2>Widerspruchsrecht</h2>
      <p>
        Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach
        Maßgabe der gesetzlichen Vorgaben jederzeit widersprechen. Der
        Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der
        Direktwerbung erfolgen.
      </p>
      <h2>Cookies und Widerspruchsrecht bei Direktwerbung</h2>
      <p>
        Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der
        Nutzer gespeichert werden. Innerhalb der Cookies können unterschiedliche
        Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu
        einem Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während
        oder auch nach seinem Besuch innerhalb eines Onlineangebotes zu
        speichern. Als temporäre Cookies, bzw. „Session-Cookies“ oder
        „transiente Cookies“, werden Cookies bezeichnet, die gelöscht werden,
        nachdem ein Nutzer ein Onlineangebot verlässt und seinen Browser
        schließt. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs
        in einem Onlineshop oder ein Login-Status gespeichert werden. Als
        „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch nach
        dem Schließen des Browsers gespeichert bleiben. So kann z.B. der
        Login-Status gespeichert werden, wenn die Nutzer diese nach mehreren
        Tagen aufsuchen. Ebenso können in einem solchen Cookie die Interessen
        der Nutzer gespeichert werden, die für Reichweitenmessung oder
        Marketingzwecke verwendet werden. Als „Third-Party-Cookie“ werden
        Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen,
        der das Onlineangebot betreibt, angeboten werden (andernfalls, wenn es
        nur dessen Cookies sind spricht man von „First-Party Cookies“).
      </p>
      <p>
        Wir können temporäre und permanente Cookies einsetzen und klären
        hierüber im Rahmen unserer Datenschutzerklärung auf.
      </p>
      <p>
        Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner
        gespeichert werden, werden sie gebeten die entsprechende Option in den
        Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies
        können in den Systemeinstellungen des Browsers gelöscht werden. Der
        Ausschluss von Cookies kann zu Funktionseinschränkungen dieses
        Onlineangebotes führen.
      </p>
      <p>
        Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des
        Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der
        Dienste, vor allem im Fall des Trackings, über die US-amerikanische
        Seite{' '}
        <a href="http://www.aboutads.info/choices/">
          http://www.aboutads.info/choices/
        </a>{' '}
        oder die EU-Seite
        <a href="http://www.youronlinechoices.com/">
          {' '}
          http://www.youronlinechoices.com/
        </a>{' '}
        erklärt werden. Des Weiteren kann die Speicherung von Cookies mittels
        deren Abschaltung in den Einstellungen des Browsers erreicht werden.
        Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen
        dieses Onlineangebotes genutzt werden können.
      </p>
      <h2>Löschung von Daten</h2>
      <p>
        Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
        Vorgaben gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern nicht
        im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die
        bei uns gespeicherten Daten gelöscht, sobald sie für ihre
        Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine
        gesetzlichen Aufbewahrungspflichten entgegenstehen.
      </p>
      <p>
        Sofern die Daten nicht gelöscht werden, weil sie für andere und
        gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung
        eingeschränkt. D.h. die Daten werden gesperrt und nicht für andere
        Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
        steuerrechtlichen Gründen aufbewahrt werden müssen.
      </p>
      <h2>Änderungen und Aktualisierungen der Datenschutzerklärung</h2>
      <p>
        Wir bitten Sie sich regelmäßig über den Inhalt unserer
        Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung
        an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen
        dies erforderlich machen. Wir informieren Sie, sobald durch die
        Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder
        eine sonstige individuelle Benachrichtigung erforderlich wird.
      </p>
      <h2>
        Administration, Finanzbuchhaltung, Büroorganisation, Kontaktverwaltung
      </h2>
      <p>
        Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie
        Organisation unseres Betriebs, Finanzbuchhaltung und Befolgung der
        gesetzlichen Pflichten, wie z.B. der Archivierung. Hierbei verarbeiten
        wir dieselben Daten, die wir im Rahmen der Erbringung unserer
        vertraglichen Leistungen verarbeiten. Die Verarbeitungsgrundlagen sind
        Art. 6 Abs. 1 lit. c. DSGVO, Art. 6 Abs. 1 lit. f. DSGVO. Von der
        Verarbeitung sind Kunden, Interessenten, Geschäftspartner und
        Websitebesucher betroffen. Der Zweck und unser Interesse an der
        Verarbeitung liegt in der Administration, Finanzbuchhaltung,
        Büroorganisation, Archivierung von Daten, also Aufgaben die der
        Aufrechterhaltung unserer Geschäftstätigkeiten, Wahrnehmung unserer
        Aufgaben und Erbringung unserer Leistungen dienen. Die Löschung der
        Daten im Hinblick auf vertragliche Leistungen und die vertragliche
        Kommunikation entspricht den, bei diesen Verarbeitungstätigkeiten
        genannten Angaben.
      </p>
      <p>
        Wir offenbaren oder übermitteln hierbei Daten an die Finanzverwaltung,
        Berater, wie z.B., Steuerberater oder Wirtschaftsprüfer sowie weitere
        Gebührenstellen und Zahlungsdienstleister.
      </p>
      <p>
        Ferner speichern wir auf Grundlage unserer betriebswirtschaftlichen
        Interessen Angaben zu Lieferanten, Veranstaltern und sonstigen
        Geschäftspartnern, z.B. zwecks späterer Kontaktaufnahme. Diese
        mehrheitlich unternehmensbezogenen Daten, speichern wir grundsätzlich
        dauerhaft.
      </p>
      <h2>Kontaktaufnahme</h2>
      <p>
        Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
        Telefon oder via sozialer Medien) werden die Angaben des Nutzers zur
        Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1
        lit. b. (im Rahmen vertraglicher-/vorvertraglicher Beziehungen), Art. 6
        Abs. 1 lit. f. (andere Anfragen) DSGVO verarbeitet.. Die Angaben der
        Nutzer können in einem Customer-Relationship-Management System ("CRM
        System") oder vergleichbarer Anfragenorganisation gespeichert werden.
      </p>
      <p>
        Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir
        überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die
        gesetzlichen Archivierungspflichten.
      </p>
      <h2>Google Tag Manager</h2>
      <p>
        Google Tag Manager ist eine Lösung, mit der wir sog. Website-Tags über
        eine Oberfläche verwalten können (und so z.B. Google Analytics sowie
        andere Google-Marketing-Dienste in unser Onlineangebot einbinden). Der
        Tag Manager selbst (welches die Tags implementiert) verarbeitet keine
        personenbezogenen Daten der Nutzer. Im Hinblick auf die Verarbeitung der
        personenbezogenen Daten der Nutzer wird auf die folgenden Angaben zu den
        Google-Diensten verwiesen. Nutzungsrichtlinien:{' '}
        <a href="https://www.google.com/intl/de/tagmanager/use-policy.html">
          https://www.google.com/intl/de/tagmanager/use-policy.html.
        </a>
      </p>
      <h2>Google Analytics</h2>
      <p>
        Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse
        an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres
        Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Google
        Analytics, einen Webanalysedienst der Google LLC („Google“) ein. Google
        verwendet Cookies. Die durch das Cookie erzeugten Informationen über
        Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an
        einen Server von Google in den USA übertragen und dort gespeichert.
      </p>
      <p>
        Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet
        hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten (
        <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active">
          https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
        </a>
        ).
      </p>
      <p>
        Google wird diese Informationen in unserem Auftrag benutzen, um die
        Nutzung unseres Onlineangebotes durch die Nutzer auszuwerten, um Reports
        über die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen
        und um weitere, mit der Nutzung dieses Onlineangebotes und der
        Internetnutzung verbundene Dienstleistungen, uns gegenüber zu erbringen.
        Dabei können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der
        Nutzer erstellt werden.
      </p>
      <p>
        Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein.
        Das bedeutet, die IP-Adresse der Nutzer wird von Google innerhalb von
        Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
        des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in
        Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in
        den USA übertragen und dort gekürzt.
      </p>
      <p>
        Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit
        anderen Daten von Google zusammengeführt. Die Nutzer können die
        Speicherung der Cookies durch eine entsprechende Einstellung ihrer
        Browser-Software verhindern; die Nutzer können darüber hinaus die
        Erfassung der durch das Cookie erzeugten und auf ihre Nutzung des
        Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung dieser
        Daten durch Google verhindern, indem sie das unter folgendem Link
        verfügbare Browser-Plugin herunterladen und installieren:
        <a href="http://tools.google.com/dlpage/gaoptout?hl=de">
          http://tools.google.com/dlpage/gaoptout?hl=de
        </a>
        .
      </p>
      <p>
        Weitere Informationen zur Datennutzung durch Google, Einstellungs- und
        Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung von
        Google (
        <a href="https://policies.google.com/privacy">
          https://policies.google.com/privacy
        </a>
        ) sowie in den Einstellungen für die Darstellung von Werbeeinblendungen
        durch Google (
        <a href="https://adssettings.google.com/authenticated">
          https://adssettings.google.com/authenticated
        </a>
        ).
      </p>
      <p>
        Die personenbezogenen Daten der Nutzer werden nach 14 Monaten gelöscht
        oder anonymisiert.
      </p>
      <h2>Google Universal Analytics</h2>
      <p>
        Wir setzen Google Analytics in der Ausgestaltung als{' '}
        <a href="https://support.google.com/analytics/answer/2790010?hl=de&ref_topic=6010376">
          „Universal-Analytics“
        </a>{' '}
        ein. „Universal Analytics“ bezeichnet ein Verfahren von Google
        Analytics, bei dem die Nutzeranalyse auf Grundlage einer pseudonymen
        Nutzer-ID erfolgt und damit ein pseudonymes Profil des Nutzers mit
        Informationen aus der Nutzung verschiedener Geräten erstellt wird (sog.
        „Cross-Device-Tracking“).
      </p>
      <h2>Onlinepräsenzen in sozialen Medien</h2>
      <p>
        Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
        Plattformen, um mit den dort aktiven Kunden, Interessenten und Nutzern
        kommunizieren und sie dort über unsere Leistungen informieren zu können.
      </p>
      <p>
        Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes
        der Europäischen Union verarbeitet werden können. Hierdurch können sich
        für die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte
        der Nutzer erschwert werden könnte. Im Hinblick auf US-Anbieter die
        unter dem Privacy-Shield zertifiziert sind, weisen wir darauf hin, dass
        sie sich damit verpflichten, die Datenschutzstandards der EU
        einzuhalten.
      </p>
      <p>
        Ferner werden die Daten der Nutzer im Regelfall für Marktforschungs- und
        Werbezwecke verarbeitet. So können z.B. aus dem Nutzungsverhalten und
        sich daraus ergebenden Interessen der Nutzer Nutzungsprofile erstellt
        werden. Die Nutzungsprofile können wiederum verwendet werden, um z.B.
        Werbeanzeigen innerhalb und außerhalb der Plattformen zu schalten, die
        mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken
        werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in
        denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert
        werden. Ferner können in den Nutzungsprofilen auch Daten unabhängig der
        von den Nutzern verwendeten Geräte gespeichert werden (insbesondere wenn
        die Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen
        eingeloggt sind).
      </p>
      <p>
        Die Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf
        Grundlage unserer berechtigten Interessen an einer effektiven
        Information der Nutzer und Kommunikation mit den Nutzern gem. Art. 6
        Abs. 1 lit. f. DSGVO. Falls die Nutzer von den jeweiligen Anbietern der
        Plattformen um eine Einwilligung in die vorbeschriebene
        Datenverarbeitung gebeten werden, ist die Rechtsgrundlage der
        Verarbeitung Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.
      </p>
      <p>
        Für eine detaillierte Darstellung der jeweiligen Verarbeitungen und der
        Widerspruchsmöglichkeiten (Opt-Out), verweisen wir auf die nachfolgend
        verlinkten Angaben der Anbieter.
      </p>
      <p>
        Auch im Fall von Auskunftsanfragen und der Geltendmachung von
        Nutzerrechten, weisen wir darauf hin, dass diese am effektivsten bei den
        Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils
        Zugriff auf die Daten der Nutzer und können direkt entsprechende
        Maßnahmen ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe
        benötigen, dann können Sie sich an uns wenden.
      </p>
      <ul>
        <li>
          Facebook, -Seiten, -Gruppen, (Facebook Ireland Ltd., 4 Grand Canal
          Square, Grand Canal Harbour, Dublin 2, Irland) auf Grundlage einer{' '}
          <a href="https://www.facebook.com/legal/terms/page_controller_addendum">
            Vereinbarung über gemeinsame Verarbeitung personenbezogener Daten
          </a>{' '}
          - Datenschutzerklärung:{' '}
          <a href="https://www.facebook.com/about/privacy/">
            https://www.facebook.com/about/privacy/
          </a>
          , speziell für Seiten:{' '}
          <a href="https://www.facebook.com/legal/terms/information_about_page_insights_data">
            https://www.facebook.com/legal/terms/information_about_page_insights_data
          </a>
          , Opt-Out:{' '}
          <a href="https://www.facebook.com/settings?tab=ads">
            https://www.facebook.com/settings?tab=ads
          </a>{' '}
          und{' '}
          <a href="http://www.youronlinechoices.com">
            http://www.youronlinechoices.com
          </a>
          , Privacy Shield:
          <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active">
            https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active
          </a>
          .
        </li>
        <li>
          Google/ YouTube (Google LLC, 1600 Amphitheatre Parkway, Mountain View,
          CA 94043, USA) – Datenschutzerklärung:
          <a href="https://policies.google.com/privacy">
            https://policies.google.com/privacy
          </a>
          , Opt-Out:{' '}
          <a href="https://adssettings.google.com/authenticated">
            https://adssettings.google.com/authenticated
          </a>
          , Privacy Shield:
          <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active">
            https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
          </a>
          .
        </li>
        <li>
          Twitter (Twitter Inc., 1355 Market Street, Suite 900, San Francisco,
          CA 94103, USA) - Datenschutzerklärung:{' '}
          <a href="https://twitter.com/de/privacy">
            https://twitter.com/de/privacy
          </a>
          , Opt-Out:{' '}
          <a href="https://twitter.com/personalization">
            https://twitter.com/personalization
          </a>
          , Privacy Shield:{' '}
          <a href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active">
            https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active
          </a>
          .
        </li>
      </ul>
      <h2>Einbindung von Diensten und Inhalten Dritter</h2>
      <p>
        Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer
        berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und
        wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6
        Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern
        ein, um deren Inhalte und Services, wie z.B. Videos oder Schriftarten
        einzubinden (nachfolgend einheitlich bezeichnet als “Inhalte”).
      </p>
      <p>
        Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die
        IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die Inhalte
        nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die
        Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche
        Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich
        zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner so
        genannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons"
        bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die
        "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den
        Seiten dieser Website ausgewertet werden. Die pseudonymen Informationen
        können ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und
        unter anderem technische Informationen zum Browser und Betriebssystem,
        verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung
        unseres Onlineangebotes enthalten, als auch mit solchen Informationen
        aus anderen Quellen verbunden werden.
      </p>
      <h2>Google Maps</h2>
      <p>
        Wir binden die Landkarten des Dienstes “Google Maps” des Anbieters
        Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA,
        ein. Zu den verarbeiteten Daten können insbesondere IP-Adressen und
        Standortdaten der Nutzer gehören, die jedoch nicht ohne deren
        Einwilligung (im Regelfall im Rahmen der Einstellungen ihrer Mobilgeräte
        vollzogen), erhoben werden. Die Daten können in den USA verarbeitet
        werden. Datenschutzerklärung:{' '}
        <a href="https://www.google.com/policies/privacy/">
          https://www.google.com/policies/privacy/
        </a>
        , Opt-Out:{' '}
        <a href="https://adssettings.google.com/authenticated">
          https://adssettings.google.com/authenticated
        </a>
        .
      </p>
      <p>
        <a href="https://datenschutz-generator.de/">
          Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke
        </a>
      </p>
      <a href="/">Back to Main page</a>
    </div>
  </Layout>
);

export default PrivacyPage;
